
.div-audio {
  display: flex;
  // align-items: flex-start;
}
.div-audio-time {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  font-size: 14px;
  align-items: flex-start;
  height: 22px;
}
.div-play-progress {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 3em;

  span {
    width: 0.3em;
    height: 1em;
    background-color: #3cefff;
  }

  span:nth-of-type(1) {
    animation: grow 1s -0.45s ease-in-out infinite;
  }

  span:nth-of-type(2) {
    animation: grow 1s -0.3s ease-in-out infinite;
  }

  span:nth-of-type(3) {
    animation: grow 1s -0.15s ease-in-out infinite;
  }

  span:nth-of-type(4) {
    animation: grow 1s ease-in-out infinite;
  }

  span:nth-of-type(5) {
    animation: grow 1s -0.4s ease-in-out infinite;
  }

  span:nth-of-type(6) {
    animation: grow 1s -0.7s ease-in-out infinite;
  }
}

@keyframes grow {
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
}
.div-play-pause {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 3em;

  span {
    width: 0.3em;
    height: 1em;
    background-color: #3cefff;
  }
}
