
.searchBar-m {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 9999;
  text-align: center;
}
.searchBar-m .mask {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
}
.searchBar-m .form-group {
  width: 70%;
  max-width: 720px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -200%);
}
.form-group {
  padding-bottom: 10px;
}
.searchBar-m .div-close-icon {
  display: block;
  position: absolute;
  right: 5%;
  top: 5%;
  width: 60px;
  height: 60px;
  line-height: 56px;
  font-size: 48px;
  z-index: 9999;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.searchBar-m .div-close-icon:hover,
.searchBar-m .div-close-icon:focus {
  color: #409eff;
}
.searchBar-m .input {
  width: 88%;
  background: transparent;
  border: 0;
  outline: none;
  border-bottom: 2px solid #f2f2f2;
  color: white;
  height: 60px;
  line-height: 60px;
  float: left;
  padding: 0;
}
// .searchBar-m .input:focus {
//   border: 0;
//   outline: none;
// }
.searchBar-m .el-button {
  width: 8%;
  height: 60px;
  display: block;
  float: left;
  border: 0;
  background-color: rgb(255 0 0 / 0%);
}

input[type="text"] {
  -webkit-appearance: none;
}
.radius-none {
  border-radius: 0;
}
.text-middle {
  font-size: 16px;
}
.el-header {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  // padding-right: 0px;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
  background-color: #191919;
  position: fixed;
  z-index: 999;
}

.el-main {
  padding: 0px !important;
  margin-top: 90px;
  background-color: #f0f2f5;
}

.el-footer {
  height: 210px;
  width: 100%;
}

.div-logo {
  width: 40%;
  // padding: 2em 0;
  display: flex;
  align-items: center;
}

.div-side-menu {
  max-width: 55%;

  .el-menu {
    border: 0px solid #191919;
  }

  .el-menu:hover {
    background-color: #191919 !important;
  }

  .el-menu-item:hover {
    outline: 0 !important;
    background-color: #191919 !important;
  }

  // .el-menu-item {
  //   font-size: 15px;
  // }

  // :deep(.el-sub-menu) {
  //   font-size: 15px;
  //   .el-sub-menu__title {
  //     font-size: 15px;
  //   }
  // }

  .el-menu-item.is-active {
    color: #fff !important;
    background-color: #191919 !important;
    border: 0px solid #191919;
  }
}

.el-menu-item,
.el-sub-menu {
  font-weight: 600;
}

.div-login {
  padding-left: 20px;
  cursor: pointer;
}

.el-footer {
  background-color: #191919;
}

.div-content-footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  bottom: 0;

  .div-logo-name {
    display: inline-flex;
    align-items: center;
  }
}

.div-footer-left-p {
  padding: 0.4em 0.6em;
  font-size: 14px;
}

.logo {
  padding-right: 20px;
}

.logo-b {
  width: 100px;
}

.example-showcase .el-loading-mask {
  z-index: 999;
}

.qrcode-item-view {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  color: #333;
  background-color: white;
}

.qrcode-img {
  width: 100px;
  height: 100px;
}

.footer-right-view {
  text-align: center;
  width: 100%;
  height: 32px;
  color: #999999;
  font-size: 12px;
}

:deep(.footer-right-view) {
  a {
    color: #999999;
  }
}

.modal-person-img {
  text-align: center;
  #user-img-modal {
    width: 200px;
    height: 200px;
    background-position: 50%;
    background-size: cover;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
    object-fit: cover;
  }
}
.modal-person-body {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  color: #000000d9;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5715;
  .div-body-title {
    width: 40%;
    text-align: right;
    padding: 16px 24px;
    color: #303133;
  }
  .div-body-content {
    width: 50%;
    text-align: left;
    padding: 16px 24px;
    color: #303133;
  }
}
.top-audio-show {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 50px;
}
.scrollText {
  overflow: hidden;
  margin-left: 0.5em;
  width: 200px;
  // height: 50px;
  &__item {
    display: inline-block;
    font-size: 14px;
    color: #ff9600;
    white-space: nowrap;
    cursor: pointer;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}
.audio-title {
  font-size: 14px;
  color: #ff9600;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
