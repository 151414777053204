
#app {
  font-family: "Microsoft Yahei", "HanHei SC", PingHei, "PingFang SC",
    "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Heiti SC",
    "WenQuanYi Micro Hei", sans-serif, PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  font-size: 16px;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  -webkit-text-size-adjust: 100%; // 防止移动Safari浏览器调整字体大小
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-loading-mask {
  background-color: rgba(122, 122, 122, 0.8) !important;
}

body {
  margin: 0px;
  font-family: "Microsoft Yahei", "HanHei SC", PingHei, "PingFang SC",
    "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Heiti SC",
    "WenQuanYi Micro Hei", sans-serif, PingFangSC-Regular, PingFang SC;
  width: 100% !important;
}

.title-font {
  font-size: 2em;
  color: #333;
  font-weight: bold;
  padding-top: 20px;
}

.margin-b {
  margin-bottom: 5px;
}

.margin-l {
  margin-left: 5px !important;
}

.el-form .el-select {
  width: 100%;
}

input.hidden {
  display: none;
}

.custom-dialog {
  .el-form-item__label {
    width: 30%;
  }

  .date-w {
    width: 100% !important;

    .el-input__wrapper {
      width: 100%;
      padding-right: 0px;
      padding-left: 0px;

      .el-input__prefix {
        margin-left: 11px;
      }
    }
  }

  .avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }

  .avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .disabled-upload {
    .el-upload.el-upload--picture-card {
      display: none;
    }
  }
}

.div-page {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.td-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-white {
  color: white;
}
.el-overlay.is-message-box {
  z-index: 9999 !important;
}
.div-main-container {
  min-height: calc(100vh - 309px);
}

.div-main-container .child-container {
  min-height: calc(100vh - 309px);
}

@media only screen and (max-width: 575px) {
  // 宽度小于575px时
  html {
    font-size: 12px;
  }

  .div-logo-img {
    display: none;
  }
}
@media all and (orientation: portrait) {
  /*竖屏*/
  .div-logo-img {
    display: none;
  }
}

@media only screen and (max-width: 1367px) {
  // 宽度小于1367px时
  .title-font {
    font-size: 24px;
  }
  .tips-title {
    font-size: 18px !important;
  }
  .banner-box .banner-content h1 {
    font-size: 24px !important;
  }
  .banner-box .banner-content h3 {
    font-size: 14px !important;
  }
  .collection-top-view .text-title {
    font-size: 18px !important;
  }
  .card-content-view .title-text {
    font-size: 18px !important;
  }
  .news-view .news-item-view .item-right .right-title {
    font-size: 18px !important;
  }
  .information-view .list-view .list-item-view .item-title-view .text-title {
    font-size: 18px !important;
  }
  .article-view
    .article-item-view
    .item-right-view
    .title-view
    .titile-content-view
    .text-title {
    font-size: 18px !important;
  }
  .article-view .article-item-view .item-right-view .time-view .item-day {
    font-size: 18px !important;
  }
  .el-tabs .el-tabs__item.is-top {
    font-size: 18px !important;
  }
  .div-footer-left-p {
    font-size: 1em;
  }
  .top-view .main-title {
    font-size: 24px !important;
  }
  .div-audio-title .div-title {
    font-size: 24px !important;
  }
  .div-audio-title .div-subtitle {
    font-size: 18px !important;
  }
  .audio-item-view .item-right-view .titile-content-view .text-title {
    font-size: 18px !important;
  }
  .bottom-content-view-left .half-content-right .content-button-view button,
  .bottom-content-view-left .half-content .content-button-view button,
  .bottom-content-view-left .half-content-right .content-button-view button {
    font-size: 14px !important;
  }
  .test-view .title-text {
    font-size: 18px !important;
  }
}
